import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { parse } from 'query-string';
import { BroadcastChannel } from 'broadcast-channel';
import { checkForVersionNumberMismatch, request } from 'utils/request';
import { Layers, WebAudio } from 'utils/audio';
import Helium from 'utils/helium';
import { describeOrg } from 'heliumApi';
import { requestAndSet } from 'utils/helpers';
import {
  activityDashboardRoute,
  alarmDashboardRoute,
  customDashboardsRoute,
  entryDashboardRoute,
  hardwareDashboardRoute,
  mapsDashboardRoute,
} from 'routes/constants';
import {
  setAccessToken as setAccessTokenAction,
  setMfaCredentials,
  setProcessedScopes,
} from 'global/accessToken/actions';
import {
  selectAccessTokenScopes,
  selectActiveScopes,
  selectCurrentOrgId,
  selectCurrentUserId,
  selectHasMasterModeAccess,
  selectMfaCredentials,
  selectProcessedScopes,
} from 'global/accessToken/selectors';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import { selectCurrentOrgParentId } from 'routes/AppContainer/selectors';
import { getItemConfig, getRawMenuItems } from 'config/utils/index';
import { checkScope, getActiveScopes, verifyApiScope } from 'utils/redirects';
import { t } from 'i18next';
import {
  validAltaAvigilonUrlRegEx,
  validDevelopmentUrlRegEx,
} from 'utils/domains';
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from 'utils/accessToken';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { getWindowLocation } from 'utils/window';
import { getDashboardOption } from 'routes/DashboardsContainer/utils';
import opConfig from 'openpathConfig';
import {
  selectCurrentIdentityLanguage,
  selectDisabledRoutes,
  selectInviteToken,
  selectIsLoginComplete,
} from './selectors';
import {
  clearAlert,
  initStoreFromCookie as initStoreFromCookieAction,
  loginSuccess,
  logoutSuccess,
  setAlert,
  setDisabledRoutes,
  setErrorMessage,
  setUpdateRequired,
  setPrefetchedVideocallAuthForDevices,
  resetPrefetchedVideocallAuth,
} from './actions';
import {
  errorMessageRoute,
  loginRoute,
  mfaManagementRoute,
  partnerBillingRoute,
  partnerDashboardRoute,
  partnerStoreRoute,
  quickStartRoute,
} from '../constants';
import {
  CHECK_ACCESS_TOKEN,
  CHECK_DISABLED_ROUTES,
  CHECK_FOR_MAINTENANCE_MODE,
  CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE,
  CHECK_INVITE_TOKEN,
  CHECK_VERSION_NUMBER,
  FETCH_GET_MFA_CREDENTIALS,
  INIT_STORE_FROM_COOKIE,
  LOGIN_SSO_CALLBACK,
  LOGOUT_REQUEST,
  MENUITEMS_ROUTES,
  REQUEST_SWITCH_ORG,
  SET_CURRENT_USER_PREFERENCES,
  PREFETCH_DEVICE_VIDEOCALL_AUTH,
} from './constants';
import { initialUserPreferences } from './reducer';
import { setWindowLocation } from '../../../altaLoginApp/helpers/setWindowLocation';

const partnerCenterRoute = opConfig.PARTNER_CENTER_APP_URL;

const openpathConfig = require('openpathConfig');

export function* requestGetMFACredentials({ identityId }) {
  const { data: mfaCredentials } = yield call(
    requestAndSet,
    'listMfaCredentials',
    [identityId],
  );
  yield put(setMfaCredentials(mfaCredentials));
  return mfaCredentials;
}

// @TODO - this function is a monolith and needs to be cleaned up soon
// - justin March 2021 (date is important for accountability)
// - Welp, I'm leaving the company (April 2022) so my accountability failed. Good luck Curt! <3
export function* login(data) {
  const identityId = data.identityId;
  yield put(setAccessTokenAction(data));

  // we need to process the scopes for later
  const processedData = {};
  const allPartnerScopes = {};
  data.tokenScopeList.forEach((s) => {
    const orgData = {
      hasSubOrgFeature: false,
      partnerScopes: s.scope.filter((f) =>
        ['s-o:r', 's-o:w', 's-o-video:r', 's-o-video:w'].includes(f),
      ),
      parentOrg: s.org.parentOrg,
      hasValidPortalAccessScopes: Boolean(s.scope.length > 2), // && (s.org.id === null || includesAnyPortalScope(s.scope))),
    };
    if (s.org.packagePlans) {
      s.org.packagePlans.forEach((p) => {
        // look for subOrg feature!
        const foundWithSubOrg = p.package.packageFeatures.find(
          (pf) =>
            pf.feature.code === 'subOrgs' || pf.feature.code === 'subOrgsVideo',
        );
        if (foundWithSubOrg) {
          orgData.hasSubOrgFeature = true;
          orgData.partnerScopes.forEach((ps) => {
            allPartnerScopes[ps] = true;
          });
        }
      });
    } else if (s.org.name !== null) {
      console.warn(
        t(`org {{name}} has no packagePlans!`, {
          name: s.org.name,
        }),
      );
    }
    // NOTE this is potentially overly pessimistic but not worth
    // doing more precisely for now - an identity MIGHT have
    // multiple roles, one of which has isFullSupportAccessRequired,
    // and one of which does not, in which case certain scopes could
    // have the limitation and other scopes not - but the only
    // effect would be that some orgs might appear as disabled in
    // the OrgSwitcher that could technically be accessible, and in
    // that case it's easy for the user to work around by just
    // navigating directly to the affected org URL
    if (
      s.scopeWithContext &&
      s.scopeWithContext.some((x) => x.context && x.context.some((y) => y.fsa))
    ) {
      orgData.isFullSupportAccessRequired = true;
    }
    processedData[s.org.id] = orgData;
  });
  // if we're a partner, we need to attach s-o to the identity for cleaner master mode checks
  // @TODO this allows us to hardcode less, but maybe we need a better scope?
  if (Object.keys(allPartnerScopes).length) {
    processedData.null.partnerScopes = Object.keys(allPartnerScopes);
    processedData.null.hasSubOrgFeature = true;
  }

  yield put(setProcessedScopes(processedData));

  // check to see if they have a pathname cached to redirect to...
  const nextPathname = getWindowLocation().pathname;
  // see if they're trying to get to an org or master mode or what...
  let currentOrgId = null;
  const pathSplit = nextPathname ? nextPathname.split('/')[1] : null;
  switch (pathSplit) {
    case 'o': {
      // they're loading an org...
      const pathnameOrg = /\/o\/[0-9]*/.exec(nextPathname);
      currentOrgId = pathnameOrg ? pathnameOrg[0].replace('/o/', '') : null;
      break;
    }
    case 'master': // they're loading into master mode, so they have no org
      currentOrgId = 'null'; // purposely set to string for consistency later
      break;
    default: // they're loading something else,so we're just going to default to the first org in their list
      currentOrgId = null;
  }

  // this allows us to end up with currentOrgId = 'null' (string) ew,...
  if (!currentOrgId && Object.keys(processedData).length) {
    currentOrgId = Object.keys(processedData).find(
      (p) => processedData[p].hasValidPortalAccessScopes,
    );
  }

  if (!currentOrgId) {
    /**
     * the user's accessToken gives them no permissions for any org, so remove
     * it from cookies so that when we later redirect to the login page,
     * they'll have a fresh chance to login (also avoids a redirect loop
     * currently) - ideally we'd avoid storing such accessTokens in cookies in
     * the first place, but we need this check here anyway as a fallback in
     * case such an accessToken did make its way into cookies for whatever
     * reason
     */
    removeAccessToken();
    getWindowLocation().href = `${loginRoute}?hasPortalAccess=false`;
  } else {
    // at this point, currentOrgId is a string!
    currentOrgId = currentOrgId === 'null' ? null : Number(currentOrgId);

    Helium.client.setToken(data.token);

    setAccessToken(data.token);

    const mfaCredentials = yield call(requestGetMFACredentials, {
      identityId,
    });

    // get the org data
    let orgInfo = null;
    let currentUserPreferences = null;

    if (currentOrgId) {
      const currentUserId = data.tokenScopeList.find(
        (s) => String(s.org?.id) === String(currentOrgId),
      )?.user.id;

      if (currentUserId) {
        // Get this user's preferences
        ({ data: currentUserPreferences } = yield call(
          requestAndSet,
          'describeUserPreferenceSet',
          [currentOrgId, currentUserId],
        ));

        const { data: userRoles } = yield call(requestAndSet, 'listUserRoles', [
          currentOrgId,
          currentUserId,
        ]);

        // Redirect to mfaManagement page if they require an MFA credential
        if (
          Boolean(userRoles.find((role) => role.isMfaRequired)) &&
          mfaCredentials.length === 0
        ) {
          yield put(
            loginSuccess({
              identityId,
              currentUserPreferences,
              errorMessage: t(
                t(
                  'You are in a role that requires MFA. Please add an MFA device.',
                ),
              ),
            }),
          );
          yield put(push(mfaManagementRoute));
          return;
        }
      }

      orgInfo = yield call(describeOrg, currentOrgId);
    }

    let parentId = null;
    let orgName = '';
    let isLicenseBased = false;
    if (orgInfo && orgInfo.data) {
      parentId = orgInfo.data.data.parentOrg
        ? orgInfo.data.data.parentOrg.id
        : null;
      orgName = orgInfo.data.data.name;
      isLicenseBased = orgInfo.data.data.isLicenseBased;
    }

    yield put(
      loginSuccess({
        identityId,
        orgId: currentOrgId,
        orgName,
        parentOrgId: parentId,
        isLicenseBased,
        currentUserPreferences,
      }),
    );
  }
}

export function* loginSSOCallback(action) {
  // depending on how we ended up here, we might already have an
  // token (as in the case of the SAML flow where the IDP calls
  // Helium directly, and then Helium redirects back to Platinum with
  // the token embedded in the query string) or not (as in the case of
  // the OIDC flow, where the IDP calls Platinum, and then Platinum
  // extracts data from the query string and calls Helium to get an
  // accessToken)

  if (action.data.token) {
    const currentIdentityLanguage = yield select(
      selectCurrentIdentityLanguage(),
    );
    const resource = `/auth/accessTokens/${action.data.token}`;
    const options = {
      method: 'GET',
      headers: {
        'Accept-Language': currentIdentityLanguage,
      },
    };

    const response = yield call(request, resource, options);
    if (response.err) {
      yield put(
        setAlert(
          'error',
          response.err.localizedMessage || response.err.message,
        ),
      );
      return;
    }

    yield call(_processLogin, {
      ...response.data.data,
      secondaryAuthRedirect: false,
    });
  } else {
    const currentIdentityLanguage = yield select(
      selectCurrentIdentityLanguage(),
    );
    const resource = `/${action.data.code}/oidc/callback`;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        state: action.data.state,
        idToken: action.data.idToken,
      }),
      headers: {
        'Accept-Language': currentIdentityLanguage,
      },
    };

    const response = yield call(request, resource, options);
    if (response.err) {
      yield put(
        setAlert(
          'error',
          response.err.localizedMessage || response.err.message,
        ),
      );
      return;
    }
    yield call(_processLogin, response.data.data.accessToken);
  }
}

// @TODO - get rid of this function or replace it and finalizeLogin
// with a combined version, because they both do approximately the
// same thing - set a cookie, call login, then find an initial route -
// this function uses checkInitialLoginForExistingCookieAndRedirect,
// while finalizeLogin has very similar logic built into itself
function* _processLogin(data) {
  yield put(clearAlert(null, 'error'));
  const token = data.token;

  setAccessToken(token);

  yield call(login, data);
  /**
   * heres a race condition here that can override the signin redirect in the login yield so
   * secondaryAuthRedirect prevents checkInitialLoginForExistingCookieAndRedirect from calling a redundant redirect.
   * This race condition currently occurs during a SSO login scenario where the user does not have permissions to
   * login. This should be considered acceptable since the redirect to signin in
   * checkInitialLoginForExistingCookieAndRedirect occurs when theres no access token which in the flow to get
   * here from an SSO login there would always be one.
   */
  yield call(checkInitialLoginForExistingCookieAndRedirect, {
    authRedirect: data.secondaryAuthRedirect ?? true, // ?? is intentional vs || since "false" is a valid value
  });
}

export function* requestOrgInitialRoute(orgId, redirectUrl) {
  const scopes = yield select(selectAccessTokenScopes());
  const currentOrgId = yield select(selectCurrentOrgId());
  const processedScopes = yield select(selectProcessedScopes());
  const parentOrgId = yield select(selectCurrentOrgParentId());

  const userScopes = getActiveScopes(
    currentOrgId,
    scopes,
    processedScopes.get(String(parentOrgId)),
  );

  const userId = scopes
    .find((s) => s.getIn(['org', 'id']) === orgId)
    ?.getIn(['user', 'id']);

  if (userId) {
    const { data: userRoles, errorMessage } = yield call(
      requestAndSet,
      'listUserRoles',
      [orgId, userId],
    );

    const mfaCredentials = yield select(selectMfaCredentials());
    if (
      !errorMessage &&
      Boolean(userRoles.find((role) => role.isMfaRequired)) &&
      mfaCredentials.size === 0
    ) {
      yield put(
        setAlert(
          'error',
          t('You are in a role that requires MFA. Please add an MFA device.'),
        ),
      );
      return { route: mfaManagementRoute, mfaRequired: true };
    }
  }

  // If someone clicks on a link they should go to that url after login
  if (redirectUrl) {
    return redirectUrl;
  }

  const [orgResponse, partnerMenuHidden, activeScopes] = yield all([
    call(requestAndSet, 'describeOrg', [orgId]),
    select(selectActiveScopes()),
  ]);

  const siteCount = orgResponse?.data?.siteCount;
  const zoneCount = orgResponse?.data?.zoneCount;
  const acuCount = orgResponse?.data?.acuCount;
  const hasSubOrgs = orgResponse?.data?.enabledFeatures?.some(
    (f) => f.code === 'subOrgs',
  );
  if (hasSubOrgs && !partnerMenuHidden) {
    if (verifyApiScope(activeScopes, 'getSummaryStats', orgId)) {
      return { route: partnerDashboardRoute };
    }
    if (checkScope(orgId, activeScopes, ['o{orgId}-erpParentStore:w'])) {
      // this checkScope can't be done with verifyApiScope because the
      // scope logic is inside a single handler (getErpLogin) on the
      // helium side
      return { route: partnerStoreRoute };
    }

    if (checkScope(orgId, activeScopes, ['o{orgId}-erpParentCenter:w'])) {
      // this checkScope can't be done with verifyApiScope because the
      // scope logic is inside a single handler (getErpLogin) on the
      // helium side
      return { route: partnerBillingRoute };
    }
  }

  // If we don't have any sites, acus, and zones, then we start on quickstart
  const canAccessQuickStart = checkScope(
    currentOrgId,
    userScopes,
    getItemConfig(getRawMenuItems(), quickStartRoute).scope,
  );

  if (!siteCount && !acuCount && !zoneCount && canAccessQuickStart) {
    return { route: quickStartRoute };
  }

  return { route: determineInitialRoute(currentOrgId, userScopes) };
}

export function* logoutRequest(action) {
  const resource = '/auth/logout';
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const options = {
    method: 'post',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };
  yield call(request, resource, options);
  removeAccessToken();

  // tell oxygen to disconnect
  const broadcastChannel = new BroadcastChannel('oxygen');
  broadcastChannel.postMessage({
    command: 'DISCONNECT',
    target: '*',
    sender: 'logout',
  });

  yield put(logoutSuccess());
  if (action.redirect) {
    getWindowLocation().href = loginRoute;
  }
}

export function* checkDisabledRoutes(action) {
  const disabledRoutes = yield select(selectDisabledRoutes());
  // early out if we don't have anything disabled
  if (!disabledRoutes.length) yield call(action.callback, true);

  const windowLocation = getWindowLocation();
  if (windowLocation.pathname === `/${errorMessageRoute}`) {
    yield call(action.callback, true);
  }

  let valid = true;
  disabledRoutes.forEach((dr) => {
    if (dr.length && windowLocation.pathname.includes(dr)) {
      valid = false;
    }
  });

  if (!valid) {
    yield put(
      setErrorMessage(
        t(
          `I'm Sorry! This page is currently disabled; if you feel this is in error, please contact an administrator for your Avigilon Alta account.`,
        ),
      ),
    );
  }
  yield call(action.callback, valid);
}

export function* checkAccessToken(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const accessToken = getAccessToken();
  if (!accessToken) {
    if (action.callback) {
      yield call(action.callback, false);
    }
    return false;
  }

  const resource = `/auth/accessTokens/${accessToken}/validate`;
  const options = {
    method: 'post',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    removeAccessToken();
    if (action.callback) {
      yield call(action.callback, false);
    }
  } else {
    if (!response.data.data.isValid) {
      removeAccessToken();
    }
    if (action.callback) {
      yield call(action.callback, response.data.data.isValid);
    }
  }
  return true;
}

export function* checkInviteToken(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const inviteToken = yield select(selectInviteToken());
  if (!inviteToken) {
    yield call(action.callback, false);
    return;
  }

  const resource = `/auth/inviteTokens/${inviteToken}/validate`;
  const options = {
    method: 'post',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield call(action.callback, false);
  } else {
    yield call(
      action.callback,
      response.data.data.isValid,
      response.data.data.err,
    );
  }
}

export function* checkResetToken(action) {
  const resetToken = (yield call(parse, getWindowLocation().search)).token;
  if (!resetToken) {
    yield call(action.callback, false);
    return;
  }

  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const resource = `/tokens/resetPasswordTokens/${resetToken}/validate`;
  const options = {
    method: 'post',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.data.data.isValid) yield call(action.callback, true);
  yield call(action.callback, false);
}

export function* initStoreFromCookie(action) {
  const accessToken = getAccessToken();
  if (accessToken) {
    const currentIdentityLanguage = yield select(
      selectCurrentIdentityLanguage(),
    );
    const resource = `/auth/accessTokens/${accessToken}`;
    const options = {
      method: 'get',
      headers: { 'Accept-Language': currentIdentityLanguage },
    };
    const response = yield call(request, resource, options);

    if (response.err) {
      // If we arrive here, the server is likely rejecting your access token. This seems to ONLY happen
      // if you're a dev and switching from prod <--> dev (for example) and the token isn't on this environment
      // so this code should catch it, clear your access token, and refresh the page to get you to login page...
      removeAccessToken();
      getWindowLocation().reload();
    } else {
      yield call(login, response.data.data);
    }
  }

  if (action?.callback) {
    yield call(action.callback);
  }
}

export function* requestCheckForMaintenanceMode(action) {
  const check = yield call(
    Helium.checkForMaintenanceMode,
    action.forceCheck,
    action.callback,
  );
  yield put(
    setDisabledRoutes(
      check && check.disabledRoutes ? check.disabledRoutes : [],
    ),
  );
}

export function* requestCheckVersionNumber() {
  const check = yield call(checkForVersionNumberMismatch);
  if (
    check &&
    String(check.hash).trim() !==
      String(openpathConfig.__GIT_COMMITHASH__).trim()
  ) {
    yield put(setUpdateRequired(true));
  }
}

function* callback() {
  const pd = yield select(selectProcessedScopes());
  const processedData = pd.toJS();

  let tempCurrentOrgId = null;
  if (Object.keys(processedData).length) {
    tempCurrentOrgId = Object.keys(processedData).find(
      (p) => processedData[p]?.hasValidPortalAccessScopes,
    );
  }

  if (tempCurrentOrgId) {
    // getWindowLocation().href = '/'
    yield put(push('/'));
  }
}

function* checkInitialLoginForExistingCookieAndRedirect({
  redirectUrl,
  authRedirect = true,
} = {}) {
  // Remove the global loader
  window.removeGlobalLoader();
  const accessToken = getAccessToken();
  if (accessToken) {
    const currentOrgId = yield select(selectCurrentOrgId());
    const hasMaster = yield select(selectHasMasterModeAccess());
    const isPartnerRedirectEnabled = yield select(
      selectFeatureFlag('IS_UPC_REDIRECT_ENABLED'),
    );

    let initialRoute = redirectUrl;

    // Check if initialRoute is full url (example: mailroom.alta.avigilon.com)
    if (
      initialRoute?.match(validAltaAvigilonUrlRegEx) ||
      initialRoute?.match(validDevelopmentUrlRegEx)
    ) {
      getWindowLocation().href = initialRoute;
      return;
    }
    if (isPartnerRedirectEnabled && currentOrgId && !hasMaster) {
      const processedScopes = yield select(selectProcessedScopes());
      const currentOrgScope = processedScopes.get(String(currentOrgId));
      const hasSubOrgFeature =
        currentOrgScope &&
        currentOrgScope.get('hasSubOrgFeature') &&
        currentOrgScope.get('partnerScopes').size;
      if (hasSubOrgFeature) {
        setWindowLocation(`${partnerCenterRoute}/${currentOrgId}`);

        return;
      }
    }
    // If a link was clicked with a url try to take them there after login
    if (initialRoute) {
      yield put(push(initialRoute));
      return;
    }

    if (currentOrgId) {
      const { route } = yield call(requestOrgInitialRoute, currentOrgId);
      initialRoute = route;
      yield put(push(`/o/${currentOrgId}/${initialRoute}`));
      return;
    }

    if (hasMaster) {
      yield put(push('/master'));
      return;
    }

    // this isn't pretty but we have to refresh to '/' to force
    // auth container to mount first so selectCurrentOrgId above is
    // ran before this function runs and we'll redirect properly
    // This should only happen in the event that we somehow loaded /login
    // after being logged and will kick us back to our default org
    // Soon I want to rewrite this whole login flow, though, to clean this logic up
    // -Justin
    yield put(initStoreFromCookieAction());
    const isLoginComplete = yield select(selectIsLoginComplete());
    if (!isLoginComplete) {
      yield call(callback);
    }
  } else if (authRedirect) {
    getWindowLocation().href = '/signin';
  }
}

export function determineInitialRoute(currentOrgId, userScopes) {
  const defaultDashboard = getDashboardOption(
    'defaultDashboard',
    'activity',
    currentOrgId,
  );

  let defaultRoute;

  switch (defaultDashboard) {
    case 'activity':
      defaultRoute = activityDashboardRoute;
      break;
    case 'alarm':
      defaultRoute = alarmDashboardRoute;
      break;
    case 'entry':
      defaultRoute = entryDashboardRoute;
      break;
    case 'hardware':
      defaultRoute = hardwareDashboardRoute;
      break;
    case 'maps':
      defaultRoute = mapsDashboardRoute;
      break;
    default: {
      const numericDefault = parseInt(defaultDashboard, 10);
      defaultRoute = `${customDashboardsRoute}/${numericDefault}`;
    }
  }

  return !defaultRoute.includes('dashboards/custom')
    ? checkScope(
        currentOrgId,
        userScopes,
        getItemConfig(getRawMenuItems(), defaultRoute).scope,
      )
      ? defaultRoute
      : MENUITEMS_ROUTES.find((route) =>
          checkScope(
            currentOrgId,
            userScopes,
            getItemConfig(getRawMenuItems(), route).scope,
          ),
        )
    : defaultRoute;
}

function setAudioLayerVolume({ currentUserPreferences }) {
  const { alarmNotificationSoundVolume } =
    currentUserPreferences || initialUserPreferences;

  WebAudio.adjustLayerVolume(
    Layers.ALARM_NOTIFICATIONS,
    alarmNotificationSoundVolume,
  );
}

export function* requestSwitchOrg({ orgId }) {
  const { route: initialRoute, mfaRequired } = yield call(
    requestOrgInitialRoute,
    orgId,
  );

  if (mfaRequired) {
    yield put(changeRoute(initialRoute, { root: true }));
  } else {
    yield put(changeRoute(initialRoute, { orgId }));
  }
}

export function* requestAndUpdateCallAuthForVideoDevice({ opVideoDeviceId }) {
  const orgId = yield select(selectCurrentOrgId());
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const userId = yield select(selectCurrentUserId());

  const resource = `/orgs/${orgId}/opvideoDevices/${opVideoDeviceId}/users/${userId}/generateUserLiveToken`;
  const options = {
    method: 'post',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
    body: JSON.stringify({
      protocol: 'lk-v1',
    }),
  };

  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
  } else {
    const { data } = response.data;
    yield put(
      setPrefetchedVideocallAuthForDevices(
        opVideoDeviceId,
        data?.wsUrl,
        data?.token,
      ),
    );
    // This is for cleaning up after alerts are cleared
    const delayTime = 120000;
    yield delay(delayTime);
    yield put(resetPrefetchedVideocallAuth(opVideoDeviceId));
  }
}

function* rootSaga() {
  yield all([
    takeEvery(LOGIN_SSO_CALLBACK, loginSSOCallback),
    takeEvery(LOGOUT_REQUEST, logoutRequest),
    takeEvery(CHECK_ACCESS_TOKEN, checkAccessToken),
    takeEvery(CHECK_DISABLED_ROUTES, checkDisabledRoutes),
    takeEvery(CHECK_INVITE_TOKEN, checkInviteToken),
    takeEvery(INIT_STORE_FROM_COOKIE, initStoreFromCookie),
    takeEvery(CHECK_FOR_MAINTENANCE_MODE, requestCheckForMaintenanceMode),
    takeEvery(CHECK_VERSION_NUMBER, requestCheckVersionNumber),
    takeEvery(
      CHECK_INITIAL_LOGIN_FOR_EXISTING_COOKIE,
      checkInitialLoginForExistingCookieAndRedirect,
    ),
    takeEvery(FETCH_GET_MFA_CREDENTIALS, requestGetMFACredentials),
    takeEvery(SET_CURRENT_USER_PREFERENCES, setAudioLayerVolume),
    takeEvery(REQUEST_SWITCH_ORG, requestSwitchOrg),
    takeEvery(
      PREFETCH_DEVICE_VIDEOCALL_AUTH,
      requestAndUpdateCallAuthForVideoDevice,
    ),
  ]);
}

export default rootSaga;
