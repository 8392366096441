import { ReactElement } from 'react';
import { P1Svg } from 'components/svgs/P1Svg';
import { P2Svg } from 'components/svgs/P2Svg';
import { P3Svg } from 'components/svgs/P3Svg';
import { P4Svg } from 'components/svgs/P4Svg';
import { P5Svg } from 'components/svgs/P5Svg';
import { PhoneFilledSvg } from 'components/svgs/PhoneFilledSvg/PhoneFilledSvg';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

export const iconLookup: Record<string, ReactElement> = {
  // success: <CheckSquareOutlined />,
  // info: <InfoCircleOutlined />,
  // warning: <ExclamationCircleOutlined />,
  // error: <WarningOutlined />,
  intercom: <PhoneFilledSvg />,
  loading: <LoadingOutlined />,
  alarmHigh: (
    <P1Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmHighText)"
    />
  ),
  alarmMedium: (
    <P3Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmMediumText)"
    />
  ),
  alarmLow: (
    <P5Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmLowText)"
    />
  ),
  p1: (
    <P1Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmHighText)"
    />
  ),
  p2: (
    <P2Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmMediumText)"
    />
  ),
  p3: (
    <P3Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmMediumText)"
    />
  ),
  p4: (
    <P4Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmLowText)"
    />
  ),
  p5: (
    <P5Svg
      className="icon"
      opacity="1"
      width="24"
      height="24"
      stroke="var(--colorAlarmLowText)"
    />
  ),
};
